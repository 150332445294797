// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
import { Environment } from './model';

export const environment: Environment = {
  // Basic
  production: false,
  isMockEnabled: true,
  baseUrl: 'https://yes-travel-backend.dev.logicsolutions.com',
  websiteCode: 'YesAndVac',
  forestageUrl: 'https://yes-travel.dev.logicsolutions.com',

  // Azure
  azureUrl:
    'https://yeslsi.b2clogin.com/yeslsi.onmicrosoft.com/oauth2/v2.0',
  azureClientId: 'aab6a851-fbfb-49f3-9efc-82233e94b17f',
  azureLoginUserFlow: 'b2c_1_yeslsi_ropc',
  azureResetPasswordUserFlow: 'B2C_1_yeslsidev_prst',
  azureTwoFactorUserFlow: 'B2C_1A_EMAIL_VALIDATION',

  // 3rd Party Plugin
};
