export enum CarouselListKey {
  Id = 'id',
  Title = 'title',
  Snippet = 'snippet',
  Author = 'author',
  Status = 'status',
  CreateDate = 'createDate',
  Order = 'order',
  CanDelete = 'canDelete',
  CanEdit = 'canEdit',
}

export enum ListStatusValue {
  Published = 'published',
  Draft = 'draft',
  PendingReview = 'pendingReview',
}

export enum CarouselListQueryKey {
  Page = 'page',
  AmountPerPage = 'amount_per_page',
  Status = 'status',
  SortBy = 'sortby',
  IsDescending = 'isDescending',
}

export enum CarouselDetailKey {
  Id = 'Id',
  Title = 'title',
  Snippet = 'snippet',
  Author = 'author',
  CreateDate = 'createDate',
  PublishDate = 'publishedDate',
  Status = 'status',
  Order = 'order',
  CanDelete = 'canDelete',
  CanEdit = 'canEdit',
}

export enum SortBy {
  Title = 'title',
  Status = 'status',
  Author = 'author',
  Creation = 'creation',
  PublishedAt = 'publishedat',
}
