import { GetListResponse } from 'projects/vacation-cms/src/app/ui/models/http';
import { CarouselListItem } from '../models/carousel-content.model';
import { ListStatusValue } from '../enums/carousel';

export const cmsCarousel: GetListResponse<CarouselListItem> = {
  is_success: true,
  message: '',
  amount: 30,
  data: [
    {
      id: 1,
      title: 'Lorem ipsum dolor sit amet consectetur pellentesque',
      status: ListStatusValue.Draft,
      author: 'createdBy',
      createdDate: '2023-12-04T05:56:46.487Z',
      canDelete: false,
      canEdit: false,
      snippet: `<div style="position: relative; width: fit-content;">
<p style="margin: 0;"><img id="templateBackground" src="http://localhost:4200/projects/vacation-cms/src/assets/homePageCarouselTemplate.png" alt="homePageCarouselTemplate.png" width="1440" height="557"></p>
<div style="width: 100%; position: absolute; top: 0; left: 0;">
<p>&nbsp;</p>
<p>&nbsp;</p>
<p style="text-align: center;">&nbsp;</p>
<p style="text-align: center;"><span style="font-size: 56px; color: rgb(255, 255, 255);"> <strong style="line-height: 56px;">Lorem ipsum dolor sit amet </strong> </span></p>
<p style="text-align: center;"><span style="font-size: 56px; color: rgb(255, 255, 255);"> <strong style="line-height: 56px;">consectetur.</strong> </span></p>
<p style="text-align: center;">&nbsp;</p>
<p style="text-align: center;"><a style="color: rgb(5, 64, 83); font-size: 20px; font-weight: 600; padding: 10px 49px; border-radius: 9999px; text-decoration: none; background-color: white; white-space: nowrap;" title="call to action" href="https://yes-travel.dev.logicsolutions.com"> Let's Vacation </a></p>
<p>&nbsp;</p>
</div>
</div>`,
      order: 0,
      createDate: '2023-12-04T05:56:46.487Z',
    },
    {
      id: 2,
      title: 'Lorem ipsum dolor sit amet consectetur pellentesque',
      status: ListStatusValue.PendingReview,
      createdBy: 'createdBy',
      createdByName: 'createdBy',
      createdAt: '2023-12-04T05:56:46.487Z',
      slug: '2',
      canDelete: true,
      canEdit: true,
      snippet: `<div style="position: relative; width: fit-content;">
<p style="margin: 0;"><img id="templateBackground" src="http://localhost:4200/projects/vacation-cms/src/assets/homePageCarouselTemplate.png" alt="homePageCarouselTemplate.png" width="1440" height="557"></p>
<div style="width: 100%; position: absolute; top: 0; left: 0;">
<p>&nbsp;</p>
<p>&nbsp;</p>
<p style="text-align: center;">&nbsp;</p>
<p style="text-align: center;"><span style="font-size: 56px; color: rgb(255, 255, 255);"> <strong style="line-height: 56px;">Lorem ipsum dolor sit amet </strong> </span></p>
<p style="text-align: center;"><span style="font-size: 56px; color: rgb(255, 255, 255);"> <strong style="line-height: 56px;">consectetur.</strong> </span></p>
<p style="text-align: center;">&nbsp;</p>
<p style="text-align: center;"><a style="color: rgb(5, 64, 83); font-size: 20px; font-weight: 600; padding: 10px 49px; border-radius: 9999px; text-decoration: none; background-color: white; white-space: nowrap;" title="call to action" href="https://yes-travel.dev.logicsolutions.com"> Let's Vacation </a></p>
<p>&nbsp;</p>
</div>
</div>`,
      author: 'createdBy',
      order: 0,
      createDate: '2023-12-04T05:56:46.487Z',
    },
    {
      id: 3,
      title: 'Lorem ipsum dolor sit amet consectetur pellentesque',
      status: ListStatusValue.PendingReview,
      createdBy: 'createdBy',
      createdByName: 'createdBy',
      createdAt: '2023-12-04T05:56:46.487Z',
      slug: '3',
      canDelete: true,
      canEdit: true,
      snippet: `<div style="position: relative; width: fit-content;">
<p style="margin: 0;"><img id="templateBackground" src="http://localhost:4200/projects/vacation-cms/src/assets/homePageCarouselTemplate.png" alt="homePageCarouselTemplate.png" width="1440" height="557"></p>
<div style="width: 100%; position: absolute; top: 0; left: 0;">
<p>&nbsp;</p>
<p>&nbsp;</p>
<p style="text-align: center;">&nbsp;</p>
<p style="text-align: center;"><span style="font-size: 56px; color: rgb(255, 255, 255);"> <strong style="line-height: 56px;">Lorem ipsum dolor sit amet </strong> </span></p>
<p style="text-align: center;"><span style="font-size: 56px; color: rgb(255, 255, 255);"> <strong style="line-height: 56px;">consectetur.</strong> </span></p>
<p style="text-align: center;">&nbsp;</p>
<p style="text-align: center;"><a style="color: rgb(5, 64, 83); font-size: 20px; font-weight: 600; padding: 10px 49px; border-radius: 9999px; text-decoration: none; background-color: white; white-space: nowrap;" title="call to action" href="https://yes-travel.dev.logicsolutions.com"> Let's Vacation </a></p>
<p>&nbsp;</p>
</div>
</div>`,
      author: 'createdBy',
      order: 0,
      createDate: '2023-12-04T05:56:46.487Z',
    },
    {
      id: 4,
      title: 'Lorem ipsum dolor sit amet consectetur pellentesque',
      status: ListStatusValue.Published,
      slug: '4',
      canDelete: true,
      canEdit: true,
      order: 0,
      createDate: '2023-12-04T05:56:46.487Z',
      snippet: `<div style="position: relative; width: fit-content;">
<p style="margin: 0;"><img id="templateBackground" src="http://localhost:4200/projects/vacation-cms/src/assets/homePageCarouselTemplate.png" alt="homePageCarouselTemplate.png" width="1440" height="557"></p>
<div style="width: 100%; position: absolute; top: 0; left: 0;">
<p>&nbsp;</p>
<p>&nbsp;</p>
<p style="text-align: center;">&nbsp;</p>
<p style="text-align: center;"><span style="font-size: 56px; color: rgb(255, 255, 255);"> <strong style="line-height: 56px;">Lorem ipsum dolor sit amet </strong> </span></p>
<p style="text-align: center;"><span style="font-size: 56px; color: rgb(255, 255, 255);"> <strong style="line-height: 56px;">consectetur.</strong> </span></p>
<p style="text-align: center;">&nbsp;</p>
<p style="text-align: center;"><a style="color: rgb(5, 64, 83); font-size: 20px; font-weight: 600; padding: 10px 49px; border-radius: 9999px; text-decoration: none; background-color: white; white-space: nowrap;" title="call to action" href="https://yes-travel.dev.logicsolutions.com"> Let's Vacation </a></p>
<p>&nbsp;</p>
</div>
</div>`,
      author: 'createdBy',
    },
    {
      id: 5,
      title: 'Lorem ipsum dolor sit amet consectetur pellentesque',
      status: ListStatusValue.Draft,
      slug: '5',
      canDelete: true,
      canEdit: true,
      order: 0,
      createDate: '2023-12-04T05:56:46.487Z',
      snippet: `<div style="position: relative; width: fit-content;">
<p style="margin: 0;"><img id="templateBackground" src="http://localhost:4200/projects/vacation-cms/src/assets/homePageCarouselTemplate.png" alt="homePageCarouselTemplate.png" width="1440" height="557"></p>
<div style="width: 100%; position: absolute; top: 0; left: 0;">
<p>&nbsp;</p>
<p>&nbsp;</p>
<p style="text-align: center;">&nbsp;</p>
<p style="text-align: center;"><span style="font-size: 56px; color: rgb(255, 255, 255);"> <strong style="line-height: 56px;">Lorem ipsum dolor sit amet </strong> </span></p>
<p style="text-align: center;"><span style="font-size: 56px; color: rgb(255, 255, 255);"> <strong style="line-height: 56px;">consectetur.</strong> </span></p>
<p style="text-align: center;">&nbsp;</p>
<p style="text-align: center;"><a style="color: rgb(5, 64, 83); font-size: 20px; font-weight: 600; padding: 10px 49px; border-radius: 9999px; text-decoration: none; background-color: white; white-space: nowrap;" title="call to action" href="https://yes-travel.dev.logicsolutions.com"> Let's Vacation </a></p>
<p>&nbsp;</p>
</div>
</div>`,
      author: 'createdBy',
    },
    {
      id: 6,
      title: 'Lorem ipsum dolor sit amet consectetur pellentesque',
      status: ListStatusValue.Published,
      slug: '6',
      canDelete: true,
      canEdit: true,
      order: 0,
      createDate: '2023-12-04T05:56:46.487Z',
      snippet: `<div style="position: relative; width: fit-content;">
<p style="margin: 0;"><img id="templateBackground" src="http://localhost:4200/projects/vacation-cms/src/assets/homePageCarouselTemplate.png" alt="homePageCarouselTemplate.png" width="1440" height="557"></p>
<div style="width: 100%; position: absolute; top: 0; left: 0;">
<p>&nbsp;</p>
<p>&nbsp;</p>
<p style="text-align: center;">&nbsp;</p>
<p style="text-align: center;"><span style="font-size: 56px; color: rgb(255, 255, 255);"> <strong style="line-height: 56px;">Lorem ipsum dolor sit amet </strong> </span></p>
<p style="text-align: center;"><span style="font-size: 56px; color: rgb(255, 255, 255);"> <strong style="line-height: 56px;">consectetur.</strong> </span></p>
<p style="text-align: center;">&nbsp;</p>
<p style="text-align: center;"><a style="color: rgb(5, 64, 83); font-size: 20px; font-weight: 600; padding: 10px 49px; border-radius: 9999px; text-decoration: none; background-color: white; white-space: nowrap;" title="call to action" href="https://yes-travel.dev.logicsolutions.com"> Let's Vacation </a></p>
<p>&nbsp;</p>
</div>
</div>`,
      author: 'createdBy',
    },
    {
      id: 7,
      title: 'Lorem ipsum dolor sit amet consectetur pellentesque',
      status: ListStatusValue.Draft,
      slug: '7',
      canDelete: true,
      canEdit: true,
      order: 0,
      createDate: '2023-12-04T05:56:46.487Z',
      snippet: `<div style="position: relative; width: fit-content;">
<p style="margin: 0;"><img id="templateBackground" src="http://localhost:4200/projects/vacation-cms/src/assets/homePageCarouselTemplate.png" alt="homePageCarouselTemplate.png" width="1440" height="557"></p>
<div style="width: 100%; position: absolute; top: 0; left: 0;">
<p>&nbsp;</p>
<p>&nbsp;</p>
<p style="text-align: center;">&nbsp;</p>
<p style="text-align: center;"><span style="font-size: 56px; color: rgb(255, 255, 255);"> <strong style="line-height: 56px;">Lorem ipsum dolor sit amet </strong> </span></p>
<p style="text-align: center;"><span style="font-size: 56px; color: rgb(255, 255, 255);"> <strong style="line-height: 56px;">consectetur.</strong> </span></p>
<p style="text-align: center;">&nbsp;</p>
<p style="text-align: center;"><a style="color: rgb(5, 64, 83); font-size: 20px; font-weight: 600; padding: 10px 49px; border-radius: 9999px; text-decoration: none; background-color: white; white-space: nowrap;" title="call to action" href="https://yes-travel.dev.logicsolutions.com"> Let's Vacation </a></p>
<p>&nbsp;</p>
</div>
</div>`,
      author: 'createdBy',
    },
    {
      id: 8,
      title: 'Lorem ipsum dolor sit amet consectetur pellentesque',
      status: ListStatusValue.PendingReview,
      createdBy: 'createdBy',
      createdByName: 'createdBy',
      slug: '8',
      canDelete: true,
      canEdit: true,
      order: 0,
      createDate: '2023-12-04T05:56:46.487Z',
      snippet: `<div style="position: relative; width: fit-content;">
<p style="margin: 0;"><img id="templateBackground" src="http://localhost:4200/projects/vacation-cms/src/assets/homePageCarouselTemplate.png" alt="homePageCarouselTemplate.png" width="1440" height="557"></p>
<div style="width: 100%; position: absolute; top: 0; left: 0;">
<p>&nbsp;</p>
<p>&nbsp;</p>
<p style="text-align: center;">&nbsp;</p>
<p style="text-align: center;"><span style="font-size: 56px; color: rgb(255, 255, 255);"> <strong style="line-height: 56px;">Lorem ipsum dolor sit amet </strong> </span></p>
<p style="text-align: center;"><span style="font-size: 56px; color: rgb(255, 255, 255);"> <strong style="line-height: 56px;">consectetur.</strong> </span></p>
<p style="text-align: center;">&nbsp;</p>
<p style="text-align: center;"><a style="color: rgb(5, 64, 83); font-size: 20px; font-weight: 600; padding: 10px 49px; border-radius: 9999px; text-decoration: none; background-color: white; white-space: nowrap;" title="call to action" href="https://yes-travel.dev.logicsolutions.com"> Let's Vacation </a></p>
<p>&nbsp;</p>
</div>
</div>`,
      author: 'createdBy',
    },
  ],
};
