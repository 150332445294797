import { Routes } from '@angular/router';
import { RoutePath } from './enums';
import { authGuard, loginPageGuard } from './guard';

const featureRoute: Routes = [
  {
    path: RoutePath.Blog,
    loadChildren: () => import('./pages/blog/blog-routes'),
  },
  {
    path: RoutePath.FrequentlyAskedQuestion,
    loadChildren: () =>
      import('./pages/frequently-ask-question/faq-routes'),
  },
  {
    path: RoutePath.HomepageCarousel,
    loadChildren: () => import('./pages/homepage-carousel/homepage-carousel-routes'),
  },
  {
    path: RoutePath.HotelOffers,
    loadComponent: () => import('./pages/profile/profile-page.component'),
  },
  {
    path: RoutePath.Profile,
    loadComponent: () => import('./pages/profile/profile-page.component'),
  },
  {
    path: RoutePath.Error,
    loadComponent: () =>
      import('./pages/error/not-found-error-page.component'),
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: RoutePath.Profile,
  },
  {
    path: '**',
    redirectTo: RoutePath.Error,
  },
];

export default [
  {
    path: RoutePath.Login,
    canActivate: [loginPageGuard],
    loadComponent: () => import('./pages/login/login-page.component'),
  },
  {
    path: '',
    canActivateChild: [authGuard],
    loadChildren: () => featureRoute,
  },
  { path: '**', redirectTo: RoutePath.Login },
] as Routes;
